let initialState = {
	bank: 1000,
	putBet: 0,
	isH17: false,
	isInquiryShown: false,
	currentComponent: '', //Dealprep, Game
};



const sharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA':
    	return {
    		...state,
    		[action.Data]: action.payload,
    	};

    case 'SHARED_LOAD_FROM_STATE':
    	return action.payload;

    default:
      return state;
  }
};

export default sharedReducer;

export let SetData = (UData, Upayload) => {
	return {
		type: 'SET_DATA',
		Data: UData,
		payload: Upayload,
	};
};

export let sharedLoadFromState = (Upayload) => {
	return {
		type: 'SHARED_LOAD_FROM_STATE',
		payload: Upayload,
	};
};