import logo from './logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import './App.css';
import MenuComponent from './Components/Menu/menu.jsx';
import DealprepComponent from './Components/DealPrep/Dealprep.jsx';
import TheGameComponent from './Components/TheGame/TheGame.jsx';
import InquiryComponent from './Components/Inquiry/Inquiry.jsx';
import {MemoryRouter as Router, Route, Routes} from 'react-router-dom';

const suitList = ["clubs", "diamonds", "hearts", "spades"];
const royalList = ["jack", "queen", "king", "ace"];

function App(props) {
  const [isLoading, setIsLoading] = useState(true);

  const cacheImgs = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);

    setIsLoading(false);
  };

  useEffect(() => {
    if(isLoading) {
      let imgs = [];

      for(let i = 0; i<4; i++) {
        for(let j = 0; j<13; j++) {
          if(j+2>10) {
            imgs.push(`./img/funnycards/${royalList[j-9]}_of_${suitList[i]}.svg`)
          } else {
            imgs.push(`./img/funnycards/${j+2}_of_${suitList[i]}.svg`);
          }
        }
      }
    }
  }, [])

  return (
    <div className="Wrapper">
      <Router>
        <Routes>
          <Route exact path="/" element={<MenuComponent />} />
          <Route path="/Dealprep" element={<DealprepComponent />} />
          <Route path="/Game" element={<TheGameComponent />} />
        </Routes>
      </Router>
      <InquiryComponent />
    </div>
  );
}

export default App;
