import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import DPReducer from './Reducers/DealPrepReducer.js';
import TGReducer from './Reducers/TheGameReducer.js';
import sharedReducer from './Reducers/SharedReducer.js';
import { sharedLoadFromState } from './Reducers/SharedReducer.js';
import throttle from 'lodash/throttle';

const saveToStorage = (state) => {
  const stateToSave = state.sharedData;
  const serializedState = JSON.stringify(stateToSave);
  localStorage.setItem("sharedStateKey", serializedState);
};

const loadFromStorage = (uStore) => {
  const result = localStorage.getItem("sharedStateKey");
  if(result) {
    const parsedResult = JSON.parse(result);
    if(parsedResult.currentComponent === 'Game') {
      parsedResult.bank -= parsedResult.putBet;
    }
    parsedResult.currentComponent = '';

    uStore.dispatch(sharedLoadFromState(parsedResult));
  }
};

const rootReducer = combineReducers({
  sharedData: sharedReducer,
  dealPrep: DPReducer,
  gaming: TGReducer,
});

// Create the Redux store
const store = createStore(rootReducer, applyMiddleware(thunk));

store.subscribe(throttle(() => {
  saveToStorage(store.getState());
}, 100));

loadFromStorage(store);

export default store;