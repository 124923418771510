import React, { useEffect, useState, useRef } from 'react';
import './TheGame.css';
import { useDispatch, useSelector } from 'react-redux';
import { dealGameAction, checkGame, hitAction, standAction, splitAction, ddAction, deckResetAction, endGameAction, playSplitAction } from '../../Data/Reducers/TheGameReducer.js';
import { SetData } from '../../Data/Reducers/SharedReducer.js';
import { Link, useNavigate } from 'react-router-dom';
import CardComponent from './CardComponent.jsx';

const TheGameComponent = () => {
	const getPBet = useSelector((state) => state.sharedData.putBet);
	const getBank = useSelector((state) => state.sharedData.bank);
	const getH17 = useSelector((state) => state.sharedData.isH17);
	//
	const getDeck = useSelector((state) => state.gaming.deck);
	//
	const getDCounter = useSelector((state) => state.gaming.dealCounter);
	const getPCounter = useSelector((state) => state.gaming.playCounter);
	//
	const getDCards = useSelector((state) => state.gaming.dealerCards);
	const getPCards = useSelector((state) => state.gaming.playerCards);
	//
	const getSplitPCard = useSelector((state) => state.gaming.splitPC);
	const getIsSplit = useSelector((state) => state.gaming.isSplit);
	//
	const getStand = useSelector((state) => state.gaming.isStand);
	//
	const getGE = useSelector((state) => state.gaming.isGameEnd);
	const getHH = useSelector((state) => state.gaming.hasHit);
	//
	const getisDD = useSelector((state) => state.gaming.isDD);
	//
	const getDFSPV = useSelector((state) => state.gaming.dumbFuckingSplitPlayVariable);

	const dispatch = useDispatch();
	const nav = useNavigate();

	const betSpanRef = useRef(null);

	const disableButtons = (isDisable) => {
		document.getElementById("HitB").disabled = isDisable;
		document.getElementById("StandB").disabled = isDisable;
		document.getElementById("SplitB").disabled = isDisable;
		document.getElementById("DDB").disabled = isDisable;
		document.getElementById("SurrenderB").disabled = isDisable;
	}

	const dealCardsAnim = (index, length) => {
        const currentCard = document.getElementById(index % 2 === 0 ? "998" : "999").children[index < 2 ? 0 : 1]

        if(currentCard) {
        	disableButtons(true);

            const cardRect = currentCard.getBoundingClientRect();
            const targetRect = document.getElementById(index % 2 === 0 ? "998" : "999").getBoundingClientRect();

            const translateX = targetRect.left - cardRect.left;
            const translateY = targetRect.top - cardRect.top;

            setTimeout(() => {
                currentCard.style.transition = "transform 0.25s ease-out";
                currentCard.style.transform = `translate(${translateX}px, ${translateY}px)`;
            }, 0);

            currentCard.addEventListener("transitionend", () => {
                currentCard.style.transition = "";
                currentCard.style.transform = "";
                currentCard.classList.remove("unmoved");
                currentCard.style.transform = `translateX(${currentCard.getAttribute('translationa')}px) rotate(${currentCard.getAttribute('rotationa')}deg)`;

                // Trigger the next animation
                if (index < length - 1) {
                    dealCardsAnim(index + 1, length);
                } else {
                	disableButtons(false);
                	dispatch(checkGame());
                	CGS_Final();
                }
            }, { once: true });
        }
    };

    const hitCardAnim = () => {
    	dispatch(hitAction()).then(() => {
    		const currentCard = document.getElementById("999").children[getPCards.length];

    		if(currentCard) {
    			disableButtons(true);

    			const cardRect = currentCard.getBoundingClientRect();
    			const targetRect = document.getElementById("999").getBoundingClientRect();

    			const translateX = targetRect.left - cardRect.left;
            	const translateY = targetRect.top - cardRect.top;

            	setTimeout(() => {
            		currentCard.style.transition = "transform 0.3s ease-out";
                	currentCard.style.transform = `translate(${translateX}px, ${translateY}px)`;
            	}, 0);

            	currentCard.addEventListener("transitionend", () => {
            		currentCard.style.transition = "";
            		currentCard.style.transform = "";
            		currentCard.classList.remove("unmoved");
            		currentCard.style.transform = `translateX(${currentCard.getAttribute('translationa')}px) rotate(${currentCard.getAttribute('rotationa')}deg)`;

            		dispatch(checkGame());
            		disableButtons(false);
            	}, { once: true })
    		}
    	});
    }

    const standCardAnim = () => {
    	dispatch(standAction()).then(() => {
    		if(getDCards.length !== document.getElementById("998").children.length) {
	    		for(let i = getDCards.length; i<document.getElementById("998").children.length; i++) {
			    	const currentCard = document.getElementById("998").children[i];

			    	if(currentCard) {
			    		disableButtons(true);

			    		const cardRect = currentCard.getBoundingClientRect();
						const targetRect = document.getElementById("998").getBoundingClientRect();

						const translateX = targetRect.left - cardRect.left;
			        	const translateY = targetRect.top - cardRect.top;

			        	setTimeout(() => {
			        		currentCard.style.transition = "transform 0.3s ease-out";
			            	currentCard.style.transform = `translate(${translateX}px, ${translateY}px)`;
			        	}, 200 * (i-2));

			        	currentCard.addEventListener("transitionend", () => {
			        		currentCard.style.transition = "";
			        		currentCard.style.transform = "";
			        		currentCard.classList.remove("unmoved");
			        		currentCard.style.transform = `translateX(${currentCard.getAttribute('translationa')}px) rotate(${currentCard.getAttribute('rotationa')}deg)`;
			        		if(i === document.getElementById("998").children.length - 1) {
			        			dispatch(checkGame());
			        		}
			        	}, { once: true })
			    	}
		    	}
	    	} else {
	    		dispatch(checkGame());
	    		disableButtons(false);
	    	}
	    });
    }

    const ddSpinAnim = () => {

		setTimeout(() => {
			betSpanRef.current.style.transition = "transform 0.45s ease-in-out";
			betSpanRef.current.style.transform = "rotate(360deg) scale(1.1)";
		}, 0)

		betSpanRef.current.addEventListener("transitionend", () => {
			betSpanRef.current.style.transition = "";
			betSpanRef.current.style.transform = "";

		}, { once: true })
    }

	const checkGState = () => {

		if(getPCounter === 21 && getDCounter === 21) {
			return 'draw';
		}else if(getPCounter === 21 || getDCounter > 21) {
			return 'win';
		} else if(getDCounter === 21 || getPCounter > 21) {
			return 'lose';
		} 

		if(getStand) {
			if(getDCounter === getPCounter) {
				return 'draw';
			}else if(getDCounter > getPCounter) {
				return 'lose';
			} else if(getDCounter < getPCounter) {
				return 'win';
			}
		}

		return null;
	}

	const CGS_Final = () => {
		if(checkGState() != null && getDCards != null && getPCards != null) {
			const puText = document.querySelector('.popUpText');
			if(checkGState() === 'win') {
				dispatch(SetData('bank', getBank + getPBet));
				puText.innerHTML = "You Won";
			} else if(checkGState() === 'lose') {
				dispatch(SetData('bank', getBank - getPBet));
				puText.innerHTML = "You Lost";
			} else if(checkGState() === 'draw') {
				puText.innerHTML = "Draw";
			}

			dispatch(endGameAction());
			disableButtons(true);
			const timeoutId = setTimeout(() => {
	        const popUpDiv = document.getElementById('popUpDivID');
	        if (popUpDiv) {
		          popUpDiv.style.opacity = '1';
		        }
		      }, 500);

		      return () => clearTimeout(timeoutId);
		}
	}

	useEffect(() => {
		dispatch(dealGameAction(getH17)).then(() => {
	        dispatch(SetData('isInquiryShown', false));
	        disableButtons(true);
	        
	        // Check if necessary values are available before calling dealAnim
	        setTimeout(() => dealCardsAnim(0, 4), 300);
	    });

	}, []);

	useEffect(() => {
		CGS_Final();
	}, [getDCounter, getPCounter]);

	useEffect(() => {
		if(getDFSPV) {
			setTimeout(() => dealCardsAnim(0, 3), 300);
		}
	}, [getDFSPV])
	
	let handleSplit = () => {
		dispatch(splitAction());
		dispatch(checkGame());
	}

	let handleDD = () => {
		if(getPBet > 0 && !getisDD && getPBet*2 <= getBank) {
			dispatch(ddAction());
			dispatch(SetData('putBet', getPBet*2));
			ddSpinAnim();
		}
	}

	let handleSurrender = () => {
		if(!getGE && !getHH && getPBet > 0) {
			let changedBet = getPBet/2;
			if(((getPBet/2)/5) % 2 != 0) {
				changedBet += 5;
			}
			dispatch(SetData('bank', getBank - changedBet));
			dispatch(SetData('putBet', changedBet));
			document.querySelector('.popUpText').innerHTML = "Surrendered";
			dispatch(endGameAction());
			disableButtons(true);
		}
	}

	let handleGoBack = () => {
		dispatch(deckResetAction());
		dispatch(SetData('currentComponent', 'Dealprep'));
		nav('/dealPrep');
	}

	let checkSplit = () => {
		if(getIsSplit) {
			return <CardComponent e={getSplitPCard} splitCard={true} key={getSplitPCard.id} isRot={false} index={0} />;
		} 
	}

	let handlePlaySplit = () => {
		dispatch(playSplitAction()).then(() => {
			disableButtons(true);

			const popUpDiv = document.getElementById('popUpDivID');
		    if (popUpDiv) {
		      popUpDiv.style.opacity = '0';
		    }
		});
		
	}



	return(
		<div className="TheGameWrapper">
			<p className="betText" >Bet: <span id="betSpan" ref={betSpanRef}>{getPBet}</span></p>

			<div className="CardGameDiv">
				<div className="DealerDiv">
					<div className="CardHolderDiv" id="998">
						{getPCards[0] !== undefined ? getDCards.map((e, index) => <CardComponent e={e} splitCard={false} key={e.id} index={index} />) : null}
					</div>
					<p className="DealerCounter counterClass"><span className="numCounter">{getDCounter}</span> Dealer</p>
				</div>

				<div className="midDiv" id="347">
					<button className="TActionB" id="HitB" onClick={() => hitCardAnim()}>Hit</button>
					<button className="TActionB" id="StandB" onClick={() => standCardAnim()}>Stand</button>
				</div>

				<div className="PandSConnectorDiv">
					<div className="PlayerDiv">
						<div className="CardHolderDiv" id="999">
							{getPCards[0] !== undefined ? getPCards.map((e, index) => <CardComponent e={e} splitCard={false} key={e.id} index={index} />) : null}
						</div>
						<p className="PlayerCounter counterClass"><span className="numCounter">{getPCounter}</span> Player</p>
					</div>

					<div className="SplitDiv" >
						{checkSplit()}
					</div>
				</div>
			</div>

			<div className="actionsDiv">
					<button className="ActionB" id="SplitB" onClick={() => handleSplit()}>Split</button>
					<button className="ActionB" id="DDB" onClick={!getisDD ? () => handleDD() : null }>Double Down</button>
					<button className="ActionB" id="SurrenderB" onClick={() => handleSurrender()}>Surrender</button>
			</div>

			{/* pop up window (set display when final game state is changed) */}
			<div className="popUpDiv" id="popUpDivID" style={{ display: getGE ? "flex" : "none" }}>
				<p className="popUpText"> Sample Text </p>
				{ getIsSplit ? <button className="popUpLink" onClick={() => handlePlaySplit()} >Play other hand</button> : <Link to="/dealPrep" className="popUpLink" onClick={() => handleGoBack()} > Go Back </Link> }
			</div>

			<div className="preloadDiv">
				{getDeck[0] !== undefined ? getDeck.map((e) => <CardComponent e={e} splitCard={false} key={e.id} index={0} />) : null}
			</div>
		</div>
	);
}

export default TheGameComponent;
