let initialState = {
	deck:[
		//{id: 0, num: 2, suit: "spade", type: "normal", isFlip: true}
	],
	dealerCards:[

	],
	dealCounter: 0,
	playerCards:[

	],
	playCounter: 0,
	isSplit: false,
	splitPC: {},
	isStand: false,
	G_isHos17: false,
	isGameEnd: false,
	isDD: false,
	hasHit: false,
	dumbFuckingSplitPlayVariable: false,
}

const suitList = ["clubs", "diamonds", "hearts", "spades"];
const royalList = ["jack", "queen", "king", "ace"];

const TGReducer = (state = initialState, action) => {


	switch(action.type) {
	case 'dealGame':

		//makes deck
		let updatedDeck = [];
		for(let i = 0; i<4; i++) {
			for(let j = 0; j<13; j++) {
				let tempnum = j+2;
				let temptype = "normal";

				if(tempnum>10) {
					temptype = royalList[tempnum-11];
					
					if(temptype === "ace") {
						tempnum = 11;
					} else {
						tempnum = 10;
					}
				}

				const tempCard = {id: (i*13)+j, num:tempnum, suit: suitList[i], type: temptype, isFlip: true};

				updatedDeck.push(tempCard);
				
			}
		}

		//shuffles the deck
		for(let i = updatedDeck.length - 1; i>0; i--) {
			const j = Math.floor(Math.random() * (i + 1));

  			[updatedDeck[i], updatedDeck[j]] = [updatedDeck[j], updatedDeck[i]];
		}

		//Hands out the cards to dealer and player

		const updatedDealCards = [updatedDeck[updatedDeck.length-1], updatedDeck[updatedDeck.length-3]];
		updatedDealCards[1].isFlip = false;

		const updatedPlayCards = [updatedDeck[updatedDeck.length-2], updatedDeck[updatedDeck.length-4]];
		updatedPlayCards[0].isFlip = false; updatedPlayCards[1].isFlip = false;

		updatedDeck.pop();
		updatedDeck.pop();
		updatedDeck.pop();
		updatedDeck.pop();

		return{
			...state,
			deck: updatedDeck,
			dealerCards: updatedDealCards,
			playerCards: updatedPlayCards,
			G_isHos17: action.IH17,
			isGameEnd: false,
			isSplit: false,
			isStand: false,
			splitPC: {},
			isDD: false,
			hasHit: false,
			dumbFuckingSplitPlayVariable: false,
		};

	case 'checkGame':

		let tempDealC = 0;
		let dealAces = 0;

		let tempPlayC = 0;
		let playAces = 0;

		//dealer cards
		for(let i = 0; i<state.dealerCards.length; i++) {
			if(state.dealerCards[i].isFlip === false) {
				tempDealC += state.dealerCards[i].num;

				if(state.dealerCards[i].type === 'ace') {
					dealAces++;
				}
			}
		}
		while(tempDealC > 21 && dealAces > 0) {
			tempDealC-= 10;
			dealAces--;
		}

		//player cards
		for(let i = 0; i<state.playerCards.length; i++) {
			tempPlayC += state.playerCards[i].num;

			if(state.playerCards[i].type === 'ace') {
				playAces++;
			}
		}
		while(tempPlayC > 21 && playAces > 0) {
			tempPlayC-= 10;
			playAces--;
		} 

		return {
			...state,
			dealCounter: tempDealC,
			playCounter: tempPlayC,
		}

	case 'hitGame':
		if(!state.isGameEnd) {
			if(state.isDD === false || (state.isDD === true && state.playerCards.length < 3)) {
				const tempCard = state.deck[state.deck.length - 1];
				let tempDeck1 = [...state.deck];

				tempCard.isFlip = false;
				tempDeck1.pop();


				return {
					...state,
					playerCards: [...state.playerCards, tempCard],
					deck: tempDeck1,
				}
			} else{
				return state;
			}
		} else {
			return state;
		}

	case 'standGame':
		if(!state.isGameEnd) {
			let tempDealCounter = state.dealCounter;
			let updatedDealerCards = [...state.dealerCards];
			let isAce = false;
			let isSoftAce = true;

			let tempDeck2 = [...state.deck];

			updatedDealerCards[0].isFlip = false;

			if(updatedDealerCards[0].type === "ace" || updatedDealerCards[updatedDealerCards.length - 2].type === "ace") {
				isAce = true;
			}

			if(updatedDealerCards[0].type === "ace" && updatedDealerCards[updatedDealerCards.length - 2].type === "ace") {
				tempDealCounter+= 1;
			} else {
				tempDealCounter+=updatedDealerCards[0].num;
			}

			//check H17
			let H17Check = state.G_isHos17 && tempDealCounter === 17 && isAce;

			while(tempDealCounter<17 || H17Check) {
				const tempCard = tempDeck2[tempDeck2.length - 1];
				tempCard.isFlip = false;

				updatedDealerCards.push(tempCard);
				tempDeck2.pop();

				if(tempDealCounter + tempCard.num > 21 && tempCard.type === "ace") {
					tempDealCounter += 1;
					if(!isAce) isSoftAce = false;
				} else {
					tempDealCounter+=tempCard.num;
				}

				if(tempCard.type === "ace") {
					isAce = true;
				}

				H17Check = state.G_isHos17 && tempDealCounter === 17 && isAce && isSoftAce;
			}

			return {
				...state,
				dealerCards: updatedDealerCards,
				isStand: true,
				deck: tempDeck2,
			}
		} else {
			return state;
		}

	case 'splitGame':
		if(!state.isGameEnd) {
			let tempPCard = {};
			let tempBool = false;

			let updatedPlayerCards = [...state.playerCards];

			if(!state.isSplit && updatedPlayerCards.length === 2 && updatedPlayerCards[0].num === updatedPlayerCards[1].num) {
				tempPCard = updatedPlayerCards[1];

				updatedPlayerCards.pop();

				tempBool = true;
			}

			return {
				...state,
				playerCards: updatedPlayerCards,
				splitPC: tempPCard,
				isSplit: tempBool,
			}
		} else {
			return state;
		}

	case 'ddGame':
		if (!state.isGameEnd) {
	        return {
	          ...state,
	          isDD: true,
	        };
	    } else {
	        return state;
	    }

	case 'deckResetGame':

		
		return {
			...state,	
			deck:[],
			dealerCards:[],
			dealCounter: 0,
			playerCards:[],
			offHolderCards:[],
			playCounter: 0,
			isSplit: false,
			splitPC: {},
			isStand: false,
			isGameEnd: false,
			isDD: false,
			dumbFuckingSplitPlayVariable: false,
		}

	case 'endGame':
		return {
			...state,
			isGameEnd: true,
			isStand: false,
			isDD: false,
			hasHit: false,
		}

	case 'playSplitGame':
		let tempSplitCard = state.splitPC;
		let tempDeck = [...state.deck];

		const updatedDealCards2 = [tempDeck[tempDeck.length-1], tempDeck[tempDeck.length-3]];
		updatedDealCards2[1].isFlip = false;

		tempDeck.pop();
		tempDeck.pop();
		tempDeck.pop();

		return {
			...state,
			isGameEnd: false,
			dealerCards: updatedDealCards2,
			playerCards: [tempSplitCard],
			dealCounter: 0,
			playCounter: 0,
			splitPC: {},
			isSplit: false,
			deck: tempDeck,
			isStand: false,
			isDD: false,
			hasHit: false,
			dumbFuckingSplitPlayVariable: true,
		}

	default:
		return state;
	}
};

export default TGReducer;

export const dealGameAction = (UIH17) => {
	return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type: 'dealGame',
                IH17: UIH17,
            });

            resolve();
        });
    };
};

export let checkGame = () => {
	return {
		type: 'checkGame',
	};
};

export let hitAction = () => {
	return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type: 'hitGame',
            });

            resolve();
        });
    };
};

export let standAction = () => {
	return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type: 'standGame',
            });

            resolve();
        });
    };
};

export let splitAction = () => {
	return {
		type: 'splitGame',
	};
};

export let ddAction = () => {
	return {
		type: 'ddGame',
	};
};

export let deckResetAction = () => {
	return {
		type: 'deckResetGame',
	};
};

export let endGameAction = () => {
	return {
		type: 'endGame',
	};
};

export let playSplitAction = () => {
	return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type: 'playSplitGame',
            });

            resolve();
        });
    };
};

//save to folder
//ad