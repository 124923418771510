import React, { useEffect, useState } from 'react';
import './Dealprep.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initAction, betAction, updateCE, RbetAction, clearAction, AllinAction, DealResetAction } from '../../Data/Reducers/DealPrepReducer.js';
import { SetData } from '../../Data/Reducers/SharedReducer.js';


const DealprepComponent = () => {
	const getSharedBank = useSelector((state) => state.sharedData.bank);
	const getCH17 = useSelector((state) => state.sharedData.isH17);

	const betAmount = useSelector((state) => state.dealPrep.currentBet);
	const bankAmount = useSelector((state) => state.dealPrep.changeableBank);
	const chipElements = useSelector((state) => state.dealPrep.ChipElements);
	const chipHElements = useSelector((state) => state.dealPrep.ChipHolderElements);
  	const dispatch = useDispatch();

  	const [isChipMoved, setIsChipMoved] = useState(false);

  	useEffect(() => {
  		dispatch(initAction(getSharedBank));
  		dispatch(SetData('isInquiryShown', false))
  		if(getSharedBank <= 0) {
  			document.querySelector('.DeathScreenDiv').style.display = 'flex';
  		}
  		if(getCH17) {
  			document.querySelector('.hos17').classList.add("greenHos17");
  		}
  	}, []);

	let addChipAnim = (num, id) => {
		const currentChip = document.getElementById(id);

		const chipHolderRect = document.getElementById("871").getBoundingClientRect();
		const currentChipRect = currentChip.getBoundingClientRect();

		const bankCheck = getSharedBank-num > 0;

		const placeholderImage = document.createElement('img');
		document.body.appendChild(placeholderImage);
		if(bankCheck) {
	    	placeholderImage.src = require(`../../img/ChipImgs/${num}chip.png`);
	    	placeholderImage.className = 'placeholder-image';
	    	
			placeholderImage.style.left = currentChipRect.left+2.5 + 'px';
	    	placeholderImage.style.top = currentChipRect.top+2.5 + 'px';
    	} else {
    		placeholderImage.style.display = "none";
    	}

		const translateX = chipHolderRect.left - currentChipRect.left;
    	const translateY = chipHolderRect.top - currentChipRect.top;

    	setTimeout(() => {
		    currentChip.style.transition = "transform 0.3s ease-out"; // Adjust duration and easing as needed
		    currentChip.style.transform = `translate(${translateX}px, ${translateY}px)`;
		}, 0);

		currentChip.addEventListener("transitionend", () => {
			document.body.removeChild(placeholderImage);
		    currentChip.style.transition = "";
		    currentChip.style.transform = "";
		    addChip(num, id);
		  }, { once: true });

	}
	let addChip = (num, id) => {
		dispatch(betAction(num, id));
		dispatch(updateCE());
	}
	let addChipAnimEnd = (num, id) => {
		document.getElementById(id).removeEventListener("transitionend", addChip(num, id));
	}

	let remChipAnim = (num, id) => {
		const currentChip = document.getElementById(id);

		const chipHolderRect = document.getElementById("871").getBoundingClientRect();
		const chipDivRect = document.getElementById("chipDivID").getBoundingClientRect();

		const translateX = ((chipDivRect.left+chipDivRect.right)/2) - ((chipHolderRect.left+chipHolderRect.right)/2);
    	const translateY = ((chipDivRect.top+chipDivRect.bottom)/2) - ((chipHolderRect.top+chipHolderRect.bottom)/2);

    	setTimeout(() => {
		    currentChip.style.transition = "transform 0.3s ease-out"; // Adjust duration and easing as needed
		    currentChip.style.transform = `translate(${translateX}px, ${translateY}px)`;
		}, 0);

		currentChip.addEventListener("transitionend", () => {
		    currentChip.style.transition = "";
		    currentChip.style.transform = "";
		    remChip(num, id);
		  }, { once: true });
	}
	let remChip = (num, id) => {
		dispatch(RbetAction(num, id));
		dispatch(updateCE());
	}
	let remChipAnimEnd = (num, id) => {
		document.getElementById(id).removeEventListener("transitionend", remChip(num, id));
	}




	let handleClear = () => {
		dispatch(clearAction());
		dispatch(updateCE());
	}
	let handleAllin = () => {
		dispatch(AllinAction());
		dispatch(updateCE());
	}
	let handleDeal = () => {
		dispatch(DealResetAction());
		dispatch(SetData('putBet', betAmount));
		dispatch(SetData('currentComponent', "Game"));
	}
	let handleH17Change = () => {
	  const updatedCH17 = !getCH17; // Toggle the value

	  dispatch(SetData('isH17', updatedCH17));

	  if (updatedCH17) {
	    document.querySelector('.hos17').classList.add("greenHos17");
	  } else {
	    document.querySelector('.hos17').classList.remove("greenHos17");
	  }
	}

	const handleAd = () => {
		dispatch(SetData("bank", 500));
		dispatch(initAction(500));

		document.querySelector('.DeathScreenDiv').style.display = 'none';
	}	

	return(
		<div className="DealPrepWrapper">
			<div className="DPWText_Div">
				<p className="DPWText">Your Bet: {betAmount}</p>
				<p className="DPWText">Bank: {bankAmount}</p>
			</div>
			<button className="hos17" onClick={() => handleH17Change()}>H17</button>
			<div className="ChipHolder" id="871">
				{chipHElements.map((e)=><button className="Chip CH_Chip" key={e.id} id={e.id} onClick={() => remChipAnim(e.Bamount, e.id)} onAnimationEnd={() => remChipAnimEnd(e.Bamount, e.id)} > <img src={require(`../../img/ChipImgs/${e.Bamount}chip.png`)} className="ChipButtonImg" /> </button>)}
			</div>

			
			{betAmount > 0 ? (
        	<Link to="/Game" onClick={() => handleDeal()} className="DealButton">Deal</Link>
      		) : (
        	<span className="disabled-link DealButton">
          		Deal
        	</span>
      		)}

			<div className="SelectorDiv">
				<div className="SelectorButtons">
					<button className="ClearButton SButton" onClick={() => handleClear()}>Clear</button>
					<button className="ALLINButton SButton" onClick={() => handleAllin()}>All in</button>
				</div>
				<div className="ChipDiv" id="chipDivID">
					{chipElements.map((e)=><button className="Chip" key={e.id} id={e.id} onClick={() => addChipAnim(e.Bamount, e.id)} onAnimationEnd={() => addChipAnimEnd(e.Bamount, e.id)} > <img src={require(`../../img/ChipImgs/${e.Bamount}chip.png`)} className="ChipButtonImg" /> </button>)}
				</div>
			</div>

			<div className="DeathScreenDiv"> 
				<p className="DeathScreenText" > Bankrupt </p>
				<button className="DeathScreenButton" onClick={()=>handleAd()}>Restart</button>
			</div>
		</div>
	);
}


export default DealprepComponent;