import React from 'react';

const CardComponent = ({ e, splitCard, index }) => {
	let srcr;
  let sclass = "cardClass unmoved";
  let blu = "blueback";

  const rot = index * 15;
  const transl = index * 15;

	if (e.isFlip) {
    srcr = require(`../../img/funnycards/${blu}.svg`);
  } else if (e.type === 'normal') {
    srcr = require(`../../img/funnycards/${e.num}_of_${e.suit}.svg`);
  } else {
    srcr = require(`../../img/funnycards/${e.type}_of_${e.suit}.svg`);
  }

  if(splitCard) {
    sclass = "cardClass SplitCardClass";
  }

  //const cardStyleR = {transform: `translateX(${transl}px) rotate(${rot}deg)`};

  return (
		  <img className={sclass} id={e.id} src={srcr} rotationa={rot} translationa={transl} cvalue={e.num} />
  );

};

export default CardComponent;