import React from 'react';
import './Inquiry.css';
import { SetData } from '../../Data/Reducers/SharedReducer.js';
import { useSelector, useDispatch } from 'react-redux';

const InquiryComponent = () => {

	const getIIS = useSelector((state) => state.sharedData.isInquiryShown);

	const dispatch = useDispatch();

	let slideWindow = () => {
		dispatch(SetData('isInquiryShown', !getIIS));
	}

	return(
		<div className="InquiryWrapper" >
			<div className="slideInWindow" style={{transform: getIIS ? "scaleX(1)" : "scaleX(0)"}}>
				<p className="slideInText" style={{width: getIIS ? "100%" : "0"}}>If you have any inquiries, the creators email is: <a className="EmailLink" href="mailto:64.businessys@gmail.com">64.businessys@gmail.com</a></p>
			</div>

			<button className="QuestionButton" onClick={() => slideWindow()}>?</button>			
		</div>
	);
}

export default InquiryComponent;