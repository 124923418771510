let initialState = {
	currentBet: 0,
	maxBank: 1000,
	changeableBank: 1000,
	ChipElements:[
		{id: "C0", Bamount: 10},
		{id: "C1", Bamount: 20},
		{id: "C2", Bamount: 50},
		{id: "C3", Bamount: 100},
		{id: "C4", Bamount: 500},
		{id: "C5", Bamount: 1000},
	],
	CHE_Amount: 0,
	ChipHolderElements:[
		//{id: "CH0", Bamount: 1}
	]
};

const BAList = [10, 20, 50, 100, 500, 1000];

let DPReducer = (state = initialState, action) => {
	switch(action.type) {

	case 'initDeal':
	
		for(let i = 0; i<state.ChipElements.length; i++) {
			if(state.ChipElements[i].Bamount > action.ABank){
				document.getElementById(state.ChipElements[i].id).style.display = "none";
			} else {
				document.getElementById(state.ChipElements[i].id).style.display = "block";
			}
		}
	
		return {
			...state,
			maxBank: action.ABank,
			changeableBank: action.ABank,
		}

	case 'changeDeal':
		if(state.changeableBank > 0) {
			const newCHE = {id: `CH${state.CHE_Amount}`, key: `CH${state.CHE_Amount}`, Bamount:action.amount};
			const updatedCHE = [...state.ChipHolderElements, newCHE];

			return{
				...state,
				currentBet: state.currentBet + action.amount,
				changeableBank: state.changeableBank - action.amount,
				CHE_Amount: state.CHE_Amount + 1,
				ChipHolderElements: updatedCHE,
			};
		} else {
			return state;
		}

	case 'rChangeDeal':
			const updatedChipHolderElements = [...state.ChipHolderElements];
  			updatedChipHolderElements.pop(); // Remove the last element
		return{
			...state,
			currentBet: state.currentBet - action.amount,
			changeableBank: state.changeableBank + action.amount,
			CHE_Amount: state.CHE_Amount - 1,
			ChipHolderElements: updatedChipHolderElements,
		};

	case 'UCE':
		for(let i = 0; i<state.ChipElements.length; i++) {
			if(state.ChipElements[i].Bamount > state.changeableBank){
				document.getElementById(state.ChipElements[i].id).style.display = "none";
			} else {
				document.getElementById(state.ChipElements[i].id).style.display = "block";
			}
		}
		return state;

	case 'clearDeal':
		return{
			...state,
			currentBet: 0,
			changeableBank: state.maxBank,
			CHE_Amount: 0,
			ChipHolderElements: []
		};

	case 'allinDeal':
		if(state.changeableBank > 0) {
			let tempBank = state.changeableBank;
			let tempCHEA = state.CHE_Amount;
			let tempA = 1000;
			let index = 1;
			let updatedChipHolderElements = [];
			while(tempBank > 0) {
				if(tempBank - tempA >= 0) {
					updatedChipHolderElements.push({id:`CH${tempCHEA}`, Bamount:tempA});
					tempCHEA += 1;
					tempBank -= tempA;
				} else {
					index += 1;
					tempA = BAList[BAList.length - index];
				}
			}
			return{
				...state,
				currentBet: state.maxBank,
				changeableBank: 0,
				CHE_Amount: tempCHEA,
				ChipHolderElements: [...state.ChipHolderElements, ...updatedChipHolderElements],
			};
		} else {
			return state;
		}
	
	case 'dealReset':

		return {
			...state,
			currentBet: 0,
			CHE_Amount: 0,
			ChipHolderElements:[],
		}

	default:
		return state;
	}	
};

export default DPReducer;

export let initAction = (UBank) => {
	return {
		type: 'initDeal',
		ABank: UBank,
	};
};

export let updateCE = () => {
	return {
		type: 'UCE'
	};
};

export let betAction = (num, id) => {
	return {
		type: 'changeDeal',
		amount: num
	};
};

export let RbetAction = (num, id) => {
	return{
		type: 'rChangeDeal',
		amount: num
	};
};

export let clearAction = () => {
	return{
		type: 'clearDeal',
	};
};

export let AllinAction = () => {
	return{
		type: 'allinDeal',
	};
};

export let DealResetAction = () => {
	return{
		type: 'dealReset',
	};
};