import React from 'react';
import { useDispatch } from 'react-redux';
import './menu.css';
import BlackjackTitle from '../../img/BlackjackTitle.webp';
import { Link } from 'react-router-dom';
import { SetData } from '../../Data/Reducers/SharedReducer.js';

const MenuComponent = () => {
	const dispatch = useDispatch();

	return (
		<div className="MenuWrapper">
			<img src={BlackjackTitle} className="MenuTitle" />
			<Link to="/Dealprep" className="MenuButton" onClick={()=>dispatch(SetData('currentComponent', 'Dealprep'))}>Play</Link>
		</div>
	);
};


export default MenuComponent;